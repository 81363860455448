// Central Redux store used by the application.  Areas of concern are split into
// feature files which are combined into the central store using the configureStore method.

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { evAPI, lmsAPI } from './api/api'
import {
  appReducer,
  batteryListReducer,
  bookingReducer,
  businessUnitsReducer,
  dashboardReducer,
  evSelectionReducer,
  helpReducer,
  languageReducer,
  myStaffReducer,
  userReducer,
  hookmanListReducer,
} from './slices'

const rootReducer = combineReducers({
  [lmsAPI.reducerPath]: lmsAPI.reducer,
  [evAPI.reducerPath]: evAPI.reducer,
  user: userReducer,
  language: languageReducer,
  help: helpReducer,
  evSelection: evSelectionReducer,
  businessUnit: businessUnitsReducer,
  myStaff: myStaffReducer,
  booking: bookingReducer,
  app: appReducer,
  dashboard: dashboardReducer,
  batteryList: batteryListReducer,
  hookmanList: hookmanListReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([lmsAPI.middleware, evAPI.middleware]),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
